import React from 'react';
import './App.css';
import logo from './assets/logo.png';
import MailchimpSubscribe from "react-mailchimp-subscribe";

const mailchimpUrl = "https://site.us22.list-manage.com/subscribe/post?u=ab31add92dc26626676aca6f6&id=ab952c862c";

function App() {
  // Custom form component using MailchimpSubscribe's render prop for handling subscriptions
  const CustomForm = () => (
    <MailchimpSubscribe
      url={mailchimpUrl}
      render={({ subscribe, status, message }) => (
        <div>
          <form
            className="email-form"
            onSubmit={e => {
              e.preventDefault();
              const email = e.target.elements.EMAIL.value; // ensure the input name is 'EMAIL'
              subscribe({ EMAIL: email });
            }}
          >
            <input
              type="email"
              name="EMAIL"
              placeholder="Your email address"
              className="email-input"
              required
            />
            <button type="submit" className="notify-button">Get notified</button>
          </form>
          {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
          {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
          {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
        </div>
      )}
    />
  );

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" className="App-logo" />
      </header>
      <main className="App-main">
        <div className="App-content">
          <button className="cognitive-button">Cognitive Platform</button>
          <h1>AI is accelerating fast, yet most companies have not fully adopted it.</h1>
          <p>We make it easy to build and integrate AI into your workflows so your team can focus on what matters.</p>
          <CustomForm />
        </div>
      </main>
    </div>
  );
}

export default App;
